<template>
  <section
      v-if="Object.keys(pricing).length"
      id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Цены подписок
      </h1>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
      >
        <b-row>
          <b-col md="6">
            <b-card
                align="center"
            >
              <!-- img -->
              <b-img
                  v-if="pricing.basicPlan.img"
                  :src="pricing.basicPlan.img"
                  class="mb-2 mt-5"
                  alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">USDT</sup>
                  <span
                      class="pricing-basic-value font-weight-bolder text-primary">{{ pricing.basicPlan.monthlyPrice }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/месяц</sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                    v-for="(data,index) in pricing.basicPlan.planBenefits"
                    :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                  v-if="currentSubscription === 1"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-success"
              >
                Текущий план
              </b-button>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
                class="popular"
                align="center"
            >
              <div
                  v-show="pricing.standardPlan.popular"
                  class="pricing-badge text-right"
              >
                <b-badge
                    variant="light-primary"
                    pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                  v-if="pricing.standardPlan.img"
                  :src="pricing.standardPlan.img"
                  class="mb-1"
                  alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">USDT</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ pricing.standardPlan.monthlyPrice }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/месяц</sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                    v-for="(data,index) in pricing.standardPlan.planBenefits"
                    :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                  v-if="currentSubscription === 2"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-success"
              >
                Текущий план
              </b-button>
              <b-button
                  v-else
                  @click="openPayment(2)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
              >
                Обновить
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {
        basicPlan: {
          title: 'Beginner',
          subtitle: '',
          img: '',
          monthlyPrice: '0',
          planBenefits: [
            'Получайте актуальные сигналы',
            'Возможность пользоваться ботом',
            'Зарабатывать на реферальной программе',
            'Интеграция с Binance',
            'Вход в сделку из платформы',
            'Вход в сделку по кнопке в боте',
            'История сделок',
            'Отмена сделки из платформы',
          ],
        },
        standardPlan: {
          title: 'PRO подписка',
          subtitle: '',
          img: '',
          popular: true,
          monthlyPrice: '30',
          planBenefits: [
            'Получайте актуальные сигналы',
            'Возможность пользоваться ботом',
            'Зарабатывать на реферальной программе',
            'Интеграция с Binance',
            'Вход в сделку из платформы',
            'Вход в сделку по кнопке в боте',
            'История сделок',
            'Отмена сделки из платформы',
            'Авто трейдинг с трейдером',
          ],
        },
      },
    }
  },
  computed: {
    ...mapState({
      currentSubscription: state => state.subscription.current,
    }),
  },
  mounted() {
    this.getCurrent()
    this.showPopup()
  },
  methods: {
    ...mapActions({
      getCurrent: 'subscription/getCurrent',
      getLinkPayments: 'payments/getLinkPayments',
    }),
    openPayment(subscriptionId) {
      this.getLinkPayments(subscriptionId).then(url => {
        window.open(url, '_blank')
      })
    },
    showPopup() {
      if (this.$route.query.status) {
        this.getCurrent()

        if (this.$route.query.status === 'error-payment') {
          this.$bvToast.toast('Если у вас возникли сложности с оплатой, обратитесь в нашу поддержку', {
            title: 'Ошибка оплаты',
            variant: 'danger',
            solid: true,
          })
        } else if (this.$route.query.status === 'success-payment') {
          this.$bvToast.toast('Можете начать торговать', {
            title: 'Оплата выполнена',
            variant: 'success',
            solid: true,
          })
        }
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style scope>
  .pricing-card {
    margin-top: 50px;
  }
</style>
